<template>
  <div class="card min-h-550px">
    <div class="card-body">
      <div class="position-relative">
        <span
          class="
            svg-icon svg-icon-3 svg-icon-gray-500
            position-absolute
            top-50
            translate-middle
            ms-6
          "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="17.0365"
              y="15.1223"
              width="8.15546"
              height="2"
              rx="1"
              transform="rotate(45 17.0365 15.1223)"
              fill="currentColor"
            />
            <path
              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <input
          type="text"
          class="form-control form-control-solid ps-10"
          name="search"
          v-model="formaBusqueda.filtro"
          placeholder="Buscar"
        />
      </div>
      <div class="separator separator-dashed my-5"></div>
      <div class="mb-5">
        <label class="fs-7 form-label fw-bold text-dark">Genero</label>
        <select
          class="form-select form-select-solid form-select-sm"
          data-control="select2"
          data-placeholder="In Progress"
          data-hide-search="true"
          v-model="formaBusqueda.genero"
        >
          <option value=""></option>
          <option v-for="genero in generos" :key="genero.id" :value="genero.id">
            {{ genero.genero }}
          </option>
        </select>
      </div>
      <div class="mb-5">
        <label class="fs-7 form-label fw-bold text-dark">Domicilio</label>
        <input
          type="text"
          class="form-control form-control form-control-solid form-control-sm"
          name="city"
          v-model="formaBusqueda.domicilio"
        />
      </div>
      <div class="mb-5">
        <label class="fs-7 form-label fw-bold text-dark">Puesto</label>
        <select
          class="form-select form-select-solid form-select-sm"
          data-control="select2"
          data-hide-search="true"
          v-model="formaBusqueda.puesto"
        >
          <option value=""></option>
          <option v-for="puesto in puestos" :key="puesto.id" :value="puesto.id">
            {{ puesto.puesto }}
          </option>
        </select>
      </div>
      <div class="mb-5">
        <label class="fs-7 form-label fw-bold text-dark">Escolaridad</label>
        <select
          class="form-select form-select-solid form-select-sm"
          data-control="select2"
          v-model="formaBusqueda.escolaridad"
        >
          <option value=""></option>
          <option v-for="esc in escolaridades" :key="esc.id" :value="esc.id">
            {{ esc.escolaridad }}
          </option>
        </select>
      </div>

      <div class="mb-5">
        <label class="fs-7 form-label fw-bold text-dark"
          >Proyecto asignado</label
        >
        <select
          class="form-select form-select-solid form-select-sm"
          data-control="select2"
          data-placeholder="Todos"
          data-hide-search="true"
          v-model="formaBusqueda.proyecto"
        >
          <option value="-1">Todos</option>
          <option value="0">Sin Proyecto</option>
          <option v-for="esc in escolaridades" :key="esc.id" :value="esc.id">
            {{ esc.escolaridad }}
          </option>
        </select>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <button
          type="button"
          @click="handleLimpiar"
          class="btn btn-active-light-primary btn-color-gray-400 btn-sm me-3"
        >
          Limpiar
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm min-w-150px"
          @click="handleBuscar"
          id="btn_buscar"
        >
          <span class="indicator-label">Buscar</span>
          <span class="indicator-progress"
            >Buscando...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useTemplate from "../../template/composables/useTemplate";
import useEmpleados from "../composables/useEmpleados";
export default {
  setup() {
    const {
      getEmpleados,
      formaBusquedaIni,
      setLimpiarBusqueda,
      loading,
      formaBusqueda,
    } = useEmpleados();
    const { generos, puestos, escolaridades } = useTemplate();
    const handleBuscar = () => {
      getEmpleados({ ...formaBusqueda.value });
    };

    const handleLimpiar = () => {
      setLimpiarBusqueda({ ...formaBusquedaIni });
    };

    watch(
      () => loading.value,
      () => {
        var btnLogin = document.querySelector("#btn_buscar");
        if (loading.value) {
          btnLogin.setAttribute("data-kt-indicator", "on");
          btnLogin.setAttribute("disabled", true);
        } else {
          btnLogin.setAttribute("data-kt-indicator", "off");
          btnLogin.setAttribute("disabled", null);
          btnLogin.removeAttribute("disabled");
        }
      }
    );

    return {
      generos,
      puestos,
      escolaridades,
      handleBuscar,
      formaBusqueda,
      handleLimpiar,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="row">
    <div
      class="col-md-4 col-xl-4 col-xxl-3"
      v-for="empleado in empleados"
      :key="empleado.id"
    >
      <div class="card card-bordered">
        <div class="card-body d-flex flex-center flex-column pt-12 p-9">
          <div class="symbol symbol-65px symbol-circle mb-5">
            <img :src="empleado.foto" alt="image" />
          </div>

          <a
            href="#"
            class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
            >{{ empleado.nombre + " " + empleado.paterno }}</a
          >

          <div class="fw-semibold text-gray-400 mb-6">
            Edad: {{ empleado.edad }} <br />Proyecto: {{ empleado.proyecto }}
            <br />Telefono: {{ empleado.telefono }}
          </div>

          <div class="d-flex flex-center flex-wrap">
            <button
              type="button"
              class="btn btn-sm btn-light-primary mx-2"
              @click="
                () => {
                  handleEditar(empleado.id);
                }
              "
            >
              <i class="bi bi-pencil"></i>
            </button>

            <button
              type="button"
              class="btn btn-sm btn-light-danger"
              @click="
                () => {
                  handleEliminar(empleado.id);
                }
              "
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import useEmpleados from "../composables/useEmpleados";
export default {
  setup() {
    const router = useRouter();
    const { empleados, setEmpleadoEliminar, formaBusqueda, getEmpleados } =
      useEmpleados();
    const handleEditar = (id) => {
      return router.push({ name: "empleados-editar", params: { cid: id } });
    };
    const handleEliminar = (id) => {
      Swal.fire({
        title: "Confirmar operación",
        text: "¿Desea eliminar al empleado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          setEmpleadoEliminar(id).then((result) => {
            if (result.ok) {
              Swal.fire("Eliminado", "Se ha eliminado el empleado", "success");
              getEmpleados(formaBusqueda.value);
              return router.replace({ name: "empleados" });
            } else {
              Swal.fire("Error", result.message, "error");
            }
          });
        }
      });
    };
    return { empleados, handleEditar, handleEliminar };
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="row">
    <div class="col-md-3">
      <empleados-busqueda></empleados-busqueda>
    </div>
    <div class="col-md-9">
      <empleados-listado v-if="busqueda"></empleados-listado>
      <empleados-blank v-else></empleados-blank>
    </div>
  </div>
</template>

<script>
import EmpleadosBusqueda from "./EmpleadosBusqueda.vue";
import EmpleadosListado from "./EmpleadosListado.vue";
import useEmpleados from "../composables/useEmpleados";
import EmpleadosBlank from "./EmpleadosBlank.vue";
export default {
  components: { EmpleadosBusqueda, EmpleadosListado, EmpleadosBlank },
  setup() {
    const { busqueda } = useEmpleados();
    return { busqueda };
  },
};
</script>

<style lang="scss" scoped>
</style>
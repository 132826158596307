import {computed} from "vue"
import {useStore} from "vuex"

const useTemplate = () => {
  const store = useStore()

  const setActiveMenu = (menu) => {
    store.dispatch("template/setActiveMenu", menu)
  }

  const setActiveSubMenu = (menu) => {
    store.dispatch("template/setActiveSubMenu", menu)
  }

  const getCatalogos = async () => {
    return await store.dispatch("template/getCatalogos")
  }

  return {
    activeMenu: computed(() => store.getters["template/activeMenu"]),
    activeSubMenu: computed(() => store.getters["template/activeSubMenu"]),
    civiles: computed(() => store.getters["template/civiles"]),
    documentos: computed(() => store.getters["template/documentos"]),
    escolaridades: computed(() => store.getters["template/escolaridades"]),
    generos: computed(() => store.getters["template/generos"]),
    medios: computed(() => store.getters["template/medios"]),
    aprendizaje: computed(() => store.getters["template/aprendizaje"]),
    cuestionario: computed(() => store.getters["template/cuestionario"]),
    personalidad: computed(() => store.getters["template/personalidad"]),
    puntualidad: computed(() => store.getters["template/puntualidad"]),
    sugeridos: computed(() => store.getters["template/sugeridos"]),
    puestos: computed(() => store.getters["template/puestos"]),
    vive: computed(() => store.getters["template/vive"]),
    ejecutivos: computed(() => store.getters["template/ejecutivos"]),
    setActiveMenu,
    setActiveSubMenu,
    getCatalogos,
  }
}

export default useTemplate

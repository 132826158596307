import api from "@/api"
import {computed} from "vue"
import {useStore} from "vuex"

const useEmpleados = () => {
  const store = useStore()

  const formaIni = {
    id: "",
    nombre: "",
    paterno: "",
    materno: "",
    fecha_nacimiento: "",
    genero_id: "0",
    telefono: "",
    domicilio: "",
    lugar_nacimiento: "",
    vive_con_id: "0",
    estatura: "",
    peso: "",
    civil_id: "0",
    hijos: "0",
    hijos_cuantos: "",
    puesto_id: "0",
    cronica: "0",
    cronica_descripcion: "",
    escolaridad_id: "0",
    escolaridad_grado: "",
    medio_id: "0",
    ejecutivo_id: "0",
    personalidad: "0",
    aprendizaje: "0",
    puntualidad: "0",
    sugerido: "0",
    referenciasPersonales: [],
    referenciasLaborales: [],
    experiencia: [],
    documentos: [],
    respuestas: [],
    foto: "",
    imagen: null,
  }

  const formaRefLabIni = {
    nombre: "",
    telefono: "",
    domicilio: "",
    empresa: "",
    id: "",
  }

  const formaRefPerIni = {
    nombre: "",
    telefono: "",
    domicilio: "",
    parentesco: "",
    id: "",
  }

  const formaExpIni = {
    empresa: "",
    tiempo: "",
    actividades: "",
    id: "",
  }

  const formaDocumentoIni = {
    id: "",
    documento_id: "",
    nombre: "",
    archivo: null,
    link: "",
  }

  const formaBusquedaIni = {
    filtro: "",
    genero: "",
    domicilio: "",
    puesto: "",
    escolaridad: "",
    proyecto: "",
  }

  const guardarEmpleado = async (empleado) => {
    const datos = new FormData()
    for (var key in empleado) {
      switch (key) {
        case "imagen":
          if (empleado[key]) {
            datos.append(key, empleado[key])
          }
          break
        case "documentos":
          datos.append(key, JSON.stringify(empleado[key]))
          empleado[key].forEach((doc) => {
            datos.append(`document_${doc.documento_id}`, doc.archivo)
          })
          break
        default:
          datos.append(key, JSON.stringify(empleado[key]))
      }
    }
    const {data} = await api.post("empleados-guardar", datos)
    return data
  }

  const getEmpleados = async (filtros) => {
    return await store.dispatch("empleados/getEmpleados", filtros)
  }

  const setVista = (vista) => {
    store.dispatch("empleados/setVista", vista)
  }

  const setLimpiarBusqueda = (forma) => {
    console.log(forma)
    store.dispatch("empleados/setLimpiarBusqueda", forma)
  }

  const getEmpleadoInfo = async (empleado) => {
    return store.dispatch("empleados/getEmpleadoInfo", empleado)
  }

  const setEmpleadoEliminar = async (empleado) => {
    const {data} = await api.post("empleados-eliminar", {empleado})
    return data
  }

  return {
    formaIni,
    formaRefLabIni,
    formaRefPerIni,
    formaExpIni,
    formaDocumentoIni,
    formaBusquedaIni,
    guardarEmpleado,
    getEmpleados,
    setVista,
    setLimpiarBusqueda,
    getEmpleadoInfo,
    setEmpleadoEliminar,
    empleados: computed(() => store.getters["empleados/empleados"]),
    loading: computed(() => store.getters["empleados/loading"]),
    busqueda: computed(() => store.getters["empleados/busqueda"]),
    vista: computed(() => store.getters["empleados/vista"]),
    formaBusqueda: computed(() => store.getters["empleados/formaBusqueda"]),
  }
}

export default useEmpleados

<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-sm table-bordered g-2">
        <thead>
          <tr class="border table-active">
            <th class="text-center">#</th>
            <th>Nombre</th>
            <th>Proyecto</th>
            <th class="text-center">Edad</th>
            <th>Telefono</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="border" v-for="empleado in empleados" :key="empleado.id">
            <td class="text-center">{{ empleado.id }}</td>
            <td>{{ empleado.nombre }}</td>
            <td>{{ empleado.proyecto }}</td>
            <td class="text-center">{{ empleado.edad }}</td>
            <td>{{ empleado.telefono }}</td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-xs btn-light-primary mx-2"
                @click="
                  () => {
                    handleEditar(empleado.id);
                  }
                "
              >
                <i class="bi bi-pencil"></i>
              </button>

              <button
                type="button"
                class="btn btn-sm btn-xs btn-light-danger"
                @click="
                  () => {
                    handleEliminar(empleado.id);
                  }
                "
              >
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border"></tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import useEmpleados from "../composables/useEmpleados";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const { empleados, formaBusqueda, getEmpleados, setEmpleadoEliminar } =
      useEmpleados();
    const handleEditar = (id) => {
      return router.push({ name: "empleados-editar", params: { cid: id } });
    };

    const handleEliminar = (id) => {
      Swal.fire({
        title: "Confirmar operación",
        text: "¿Desea eliminar al empleado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          setEmpleadoEliminar(id).then((result) => {
            if (result.ok) {
              Swal.fire("Eliminado", "Se ha eliminado el empleado", "success");
              getEmpleados(formaBusqueda.value);
              return router.replace({ name: "empleados" });
            } else {
              Swal.fire("Error", result.message, "error");
            }
          });
        }
      });
    };

    return { empleados, handleEditar, handleEliminar };
  },
};
</script>

<style lang="scss" scoped>
</style>
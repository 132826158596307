<template>
  <div class="card min-h-550px">
    <div class="card-body">
      <div class="card-px text-center pt-15 pb-15">
        <h2 class="fs-2x fw-bold mb-0">Busqueda de Empleados</h2>
        <p class="text-gray-400 fs-4 fw-semibold py-7">
          Favor de realizar una busqueda<br />en el formulario
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="card min-h-550px">
    <div class="card-header">
      <h5 class="card-title">Empleados</h5>
      <div class="card-toolbar">
        <div class="d-flex flex-wrap my-1">
          <!--begin::Tab nav-->
          <ul class="nav nav-pills me-6 mb-2 mb-sm-0">
            <li class="nav-item m-0">
              <a
                class="
                  btn
                  btn-sm
                  btn-icon
                  btn-light
                  btn-color-muted
                  btn-active-primary
                  me-3
                "
                :class="{ active: vista == 'cuadros' }"
                @click="
                  () => {
                    handleVista('cuadros');
                  }
                "
              >
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect
                        x="5"
                        y="5"
                        width="5"
                        height="5"
                        rx="1"
                        fill="currentColor"
                      />
                      <rect
                        x="14"
                        y="5"
                        width="5"
                        height="5"
                        rx="1"
                        fill="currentColor"
                        opacity="0.3"
                      />
                      <rect
                        x="5"
                        y="14"
                        width="5"
                        height="5"
                        rx="1"
                        fill="currentColor"
                        opacity="0.3"
                      />
                      <rect
                        x="14"
                        y="14"
                        width="5"
                        height="5"
                        rx="1"
                        fill="currentColor"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </a>
            </li>
            <li class="nav-item">
              <a
                class="
                  btn
                  btn-sm
                  btn-icon
                  btn-light
                  btn-color-muted
                  btn-active-primary
                "
                :class="{ active: vista == 'lista' }"
                @click="
                  () => {
                    handleVista('lista');
                  }
                "
              >
                <span class="svg-icon svg-icon-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </li>
            <li class="nav-item">
              <button
                class="btn btn-sm btn-success btn-active-success"
                type="button"
              >
                <i class="fa fa-file-excel"></i> Excel
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <empleados-vista-cuadros
        v-if="vista == 'cuadros'"
      ></empleados-vista-cuadros>
      <empleados-vista-lista v-if="vista == 'lista'"></empleados-vista-lista>
    </div>
  </div>
</template>

<script>
import useEmpleados from "../composables/useEmpleados";
import EmpleadosVistaCuadros from "./EmpleadosVistaCuadros.vue";
import EmpleadosVistaLista from "./EmpleadosVistaLista.vue";
export default {
  components: { EmpleadosVistaCuadros, EmpleadosVistaLista },
  setup() {
    const { vista, setVista, empleados } = useEmpleados();
    const handleVista = (vista) => {
      setVista(vista);
    };
    return { handleVista, vista, empleados };
  },
};
</script>

<style lang="scss" scoped>
</style>